import React from "react";
import "./Footer.css";
import { withTranslation } from "react-i18next";

function Footer({
    logo,
    t,
}) {
    return (
        <div className="footer">
            <div className="row heading-row">
                <div className="content-left">
                    <div className="footer-navy-logo" />
                    <h2 className="navy-header">{t("VMS")}</h2>
                </div>
                <div className="content-right">
                    <h2 className="contacts-header">{t("Contacts")}</h2>
                </div>
            </div>
            <div className="row details-row">
                <div className="content-left">
                    <div className="footer-soc-icons-wrapper">
                        {logo.map((value) => (
                            <img
                                alt="index"
                                className="footer-soc-icon"
                                onClick={() => window.open(value.link)}
                                key={value.link}
                                src={value.image}
                            />
                        ))}
                    </div>
                </div>
                <div className="content-right">
                    <div>
                        <div className="contact-item">
                            <div className="icon icon-phone" />
                            <h3 className="contact-text">
                                {t("Address")}
                            </h3>
                        </div>
                        <div className="contact-item">
                            <div className="icon icon-message" />
                            <a
                                className="contact-text"
                                href="mailto:admindep@navy.mil.gov.ua"
                            >
                                admindep@navy.mil.gov.ua
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className="contact-item">
                            <div className="icon icon-phone" />
                            <a className="contact-text" href="tel:048) 779-82-04">(048) 779-82-04</a>
                        </div>
                        <div className="contact-item">
                            <div className="icon icon-phone" />
                            <a className="contact-text" href="tel:(048) 779-82-06">(048) 779-82-06</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation("footer")(Footer);
