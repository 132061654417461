import React from "react";
import "./General.css";
import Layout from "../../components/layout/Layout";
import NavyFace from "../../components/navyFace/NavyFace";
import News from "../../components/news/News";
import Career from "../../components/carera/Career";

function General() {
    return (
        <div>
            <Layout>
                <NavyFace />
                <News />
                <Career />
            </Layout>
        </div>
    );
}
export default General;
