import React, { useState } from "react";
import "./Header.css";
import { withTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import ellipse from "../../assets/ellipse.svg";
import LanguageState from "../languageState/LanguageState";
import Sidebar from "../sidebar/Sidebar";
import Search from "../search/Search";

function Header({
    logo,
    t,
    isSearchActive,
}) {
    const [showSearch, setShowSearch] = useState(isSearchActive);
    const navigate = useNavigate();

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    function handleClick(event, route) {
        event.preventDefault();
        navigate(route);
        scrollToTop();
    }

    function onAutoFocus() {
        setShowSearch.current.focus();
    }
    function clickSearch(e) {
        setShowSearch(e);
        onAutoFocus();
    }

    let prevScrollpos = window.pageYOffset;
    window.onscroll = () => {
        const currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
            document.getElementById("headerId").style.top = "0";
        } else {
            document.getElementById("headerId").style.top = "-200px";
        }
        prevScrollpos = currentScrollPos;
    };

    return (
        <div className="header" id="headerId">
            <div className="header-content">

                <div className="header-left-content">
                    <div onClick={(e) => handleClick(e, "/")} className="container-block-logo">
                        <Link className="container-block-logo" to="/">
                            <div className="navy-logo" />
                            <h2>{t("VMS")}</h2>
                        </Link>
                    </div>
                    <div className="logobar">
                        {logo.map((value) => (
                            <img
                                alt="logo"
                                className="header-soc-icons"
                                onClick={() => window.open(value.link)}
                                key={value.link}
                                src={value.image}
                            />
                        ))}
                    </div>
                </div>

                <div className={showSearch ? "hide-block" : "navbar"}>
                    <Link
                        className="nav-item"
                        to="/news"
                    >
                        {t("News")}
                    </Link>
                    <img alt="ellipse" src={ellipse} />
                    <Link
                        className="nav-item"
                        to="/career"
                    >
                        {t("Creer")}
                    </Link>
                    <img alt="ellipse" src={ellipse} />
                    <Link
                        className="nav-item"
                        to="/contacts"
                    >
                        {t("Contacts")}
                    </Link>
                </div>

                <div className={showSearch ? "hide-block" : "header-right-content"}>
                    <div className="style-state-language">
                        <LanguageState />
                    </div>
                    <div
                        className="search-button"
                        onClick={() => {
                            clickSearch(!showSearch);
                        }}
                    />
                    <Sidebar />
                </div>
                <div className={showSearch ? "search-wrapper slide-in-right-animation" : "hide-block"}>
                    <Search toggleSearch={setShowSearch} />
                </div>
            </div>
        </div>
    );
}

export default withTranslation("header")(Header);
